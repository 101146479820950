// Standard Sizes

// Display 1 (6rem = 90px)
// Display 2 (5.5rem = 82.5px)
// Display 3 (4.5rem = 67.5px)
// Display 4 (3.5rem = 52.5px)
// h1 (2.5rem = 40px)
// h2 (2rem = 32px)
// h3 (1.75rem = 28px)
// h4 (1.5rem = 24px)
// h5 (1.25rem = 20px)
// h6 (1rem = 16px)
// p (1rem = 16px)

html {
  font-size: 10px;
}

@media (min-width: 200px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 250px) {
  html {
    font-size: 12.5px;
  }
}

@media (min-width: 350px) {
  html {
    font-size: 13px;
  }
}

@media (min-width: 450px) {
  html {
    font-size: 13.5px;
  }
}

@media (min-width: 600px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 750px) {
  html {
    font-size: 15px;
  }
}

@media (min-width: 900px) {
  html {
    font-size: 16px;
  }
}
