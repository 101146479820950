// Panel Flex

.panelFlex {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
}

@media (min-width: 300px) {
  .panelFlex {
    margin: 3px 5px 3px 5px;
  }
}

@media (min-width: 600px) {
  .panelFlex {
    margin: 6px 10px 6px 10px;
  }
}

@media (min-width: 750px) {
  .panelFlex {
    margin: 30px;
  }
  .maximumWidth {
    max-width: 100vw;
    padding: 30px 10vw 0 10vw;
  }
}

@media (min-width: 900px) {
  .panelFlex {
    margin: 45px;
  }
}

@media (min-width: 1200px) {
  .panelFlex {
    margin: 60px 100px 60px 100px;
  }
}

// Left input, right results panels

.panelFlexLeftToRight {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
}

.panelFlexLeft {
  flex-basis: 30%;
  min-height: 100px;
  align-self: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  @extend .panelNeumorphism;
}

.panelFlexRight {
  flex-basis: 60%;
  min-height: 100px;
  align-self: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  @extend .panelNeumorphism;
}

// Common Panel Elements

%commonPanel {
  min-height: 100px;
  flex-grow: 1;
  @extend .panelNeumorphism;
}

@media (min-width: 600px) {
  %commonPanel {
    border-radius: 10px;
    padding: 15px;
    margin: 20px 20px 0px 20px;
  }
}

@media (min-width: 900px) {
  %commonPanel {
    padding: 30px;
    margin: 25px 25px 0px 25px;
  }
}

.explainerPanel {
  flex-basis: 100%;
  min-height: 100px;
  align-self: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  @extend .panelNeumorphism;
}

.inputPanel {
  flex-basis: 100%;
  min-height: 100px;
  flex-grow: 1;
  flex-shrink: 1;
  @extend .panelNeumorphism;
}

@media (min-width: 600px) {
  .explainerPanel {
    padding: 15px;
    margin: 20px 20px 0px 20px;
    flex-basis: 0%;
    border-radius: 10px;
    background-color: #ffffff;
  }
  .inputPanel {
    padding: 15px;
    border-radius: 10px;
    margin: 20px 20px 0px 20px;
    background-color: #ffffff;
  }
  .resultsPanel {
    padding: 24px 24px 24px 24px !important;
  }
  .panelFlexLeft {
    padding: 15px;
    border-radius: 10px;
    background-color: #ffffff;
  }
  .panelFlexRight {
    border-radius: 10px;
    padding: 15px;
    background-color: #ffffff;
    margin: 0px;
  }
}

@media (min-width: 750px) {
  .explainerPanel {
    padding: 15px;
    margin: 20px 0px 0px 20px;
  }
  .inputPanel {
    flex-basis: 0%;
    min-width: 300px;
  }
}

@media (min-width: 900px) {
  .explainerPanel {
    padding: 30px;
    margin: 25px 0px 0px 25px;
    flex-basis: 20%;
  }
  .inputPanel {
    padding: 30px;
    margin: 25px 25px 0px 25px;
    min-width: 375px;
  }
}

.panel {
  align-self: center;
  flex-grow: 1;
}

.resultsBoxFlex {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  @extend %commonPanel;
}

.resultsPanel {
  flex-basis: 100%;
  @extend %commonPanel;
  padding: 0px 5px 0px 5px;
}

.advancedPanel {
  flex-basis: 100%;
  @extend %commonPanel;
}

.disclaimerPanel {
  flex-basis: 100%;
  @extend %commonPanel;
}

.aboutPanel {
  flex-basis: 100%;
  @extend %commonPanel;
}

.headerPanel {
  flex-basis: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.footerPanel {
  flex-basis: 100%;
  height: 42px;
  margin-top: 38px;
}

@media (min-width: 900px) {
  .footerPanel {
    height: 56px;
    margin-top: 44px;
  }
}
