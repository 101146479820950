// Custom Variables: Make Changes Here

// If you change colours here, also change them in elements/graphFill.js
$primary: #2b5e3a; // your custom primary color
$info: #5e2b4f;
$danger: #a05e2c;

// Adjusted card.scss card column media query from sm to lg

.nav-item {
  @extend .resultsNeumorphism;
  color: $primary;
  margin-right: 10px;
  border-width: 1px !important;
  border-color: $primary;
  border-style: solid;
}

// .alert {
//   @extend .resultsNeumorphism;
// }

// Bootstrap Imports

@import "./bootstrap/functions";
@import "./bootstrap/variables";
@import "./bootstrap/mixins";
@import "./bootstrap/root";
@import "./bootstrap/reboot";
@import "./bootstrap/type";
@import "./bootstrap/images";
@import "./bootstrap/code";
@import "./bootstrap/grid";
@import "./bootstrap/tables";
@import "./bootstrap/forms";
@import "./bootstrap/buttons";
@import "./bootstrap/transitions";
@import "./bootstrap/dropdown";
@import "./bootstrap/button-group";
@import "./bootstrap/input-group";
@import "./bootstrap/custom-forms";
@import "./bootstrap/nav";
@import "./bootstrap/navbar";
@import "./bootstrap/card";
@import "./bootstrap/breadcrumb";
@import "./bootstrap/pagination";
@import "./bootstrap/badge";
@import "./bootstrap/jumbotron";
@import "./bootstrap/alert";
@import "./bootstrap/progress";
@import "./bootstrap/media";
@import "./bootstrap/list-group";
@import "./bootstrap/close";
@import "./bootstrap/toasts";
@import "./bootstrap/modal";
@import "./bootstrap/tooltip";
@import "./bootstrap/popover";
@import "./bootstrap/carousel";
@import "./bootstrap/spinners";
@import "./bootstrap/utilities";
@import "./bootstrap/print";
