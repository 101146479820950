// The flex that holds all the results

.resultsFlex {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.resultsButton {
  @extend .resultsNeumorphism;
  margin-left: 0px;
  margin-top: 10px;
  font-size: 0.8em;
  width: 100%;
}

.detailsButton {
  font-size: 0.8em;
}

@media (min-width: 480px) {
  .resultsButton {
    margin-left: 10px;
    width: auto;
    height: 2.5em;
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .detailsButton {
    margin-left: 0px !important;
  }
  .compareButton {
    margin-right: auto;
  }
}

@media (min-width: 650px) {
  .resultsButton {
    font-size: 1em;
  }
  .detailsButton {
    font-size: 1em;
  }
}

// Standard box that holds results

.resultsBox {
  @extend %lightInsetShadow;
  border-radius: 5px;
  flex-grow: 1;
  flex-shrink: 1;
  margin: 2px -20px 2px -20px;
  padding: 10px 20px 10px 10px;
  position: relative;
  // @extend .resultsNeumorphism;
}

// Allow for the popover

.resultsBoxContent {
  position: absolute;
  left: calc(100% - 17px);
  top: calc(2px);
}

// Adjusted results box just for graphs

.resultsGraphBox {
  @extend %lightInsetShadow;
  border-radius: 5px;
  flex-grow: 1;
  flex-shrink: 1;
  margin: 2px -20px 2px -20px;
  padding: 10px 20px 10px 10px;
  position: relative;
  min-width: 100%;
}

@media (min-width: 1440px) {
  .resultsGraphBox {
    min-width: 25%;
    min-width: calc(50% - 10px);
  }
}

.resultsAdBox {
  border-radius: 5px;
  flex-grow: 1;
  flex-shrink: 1;
  margin: 16px 7px 16px 7px;
  position: relative;
  min-width: calc(50% - 10px);
}

// Box that holds hints

.resultsHintBox {
  @extend %lightInsetShadow;
  border-radius: 5px;
  flex-grow: 1;
  flex-shrink: 1;
  margin: 2px -20px 2px -20px;
  padding: 13px 40px 13px 20px;
}

// Flex Inner Div for the graph results boxes

.resultsGraphFlex {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

// For Victory graphs

.resultsGraph {
  max-width: 100%;
  margin-right: -10px;
  margin-left: -8px;
  min-width: 90%;
}

// For buttons at bottom of results flex

.resultsButtonFlex {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: 2px;
  margin-right: 2px;
  margin-top: 3px;
}

.resultsTopFlex {
  align-items: flex-end;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  margin: 5px 2px 5px 2px;
}

// At screen sizes larger than 450px

@media (min-width: 450px) {
  .resultsFlex {
    padding: 10px 10px 2px 10px;
  }
  .resultsBox {
    min-width: 150px;
    padding: 15px 25px 15px 15px;
    margin: 5px;
  }
  .resultsGraphBox {
    margin: 5px;
  }
  .resultsHintBox {
    margin: 5px;
    max-height: 150px;
  }
  .resultsGraph {
    margin-right: -20px;
    margin-left: -10px;
  }
  .resultsButtonFlex {
    margin-left: 15px;
    margin-right: 15px;
  }
  .resultsTopFlex {
    margin-left: 15px;
    margin-right: 15px;
  }
  .resultsBoxContent {
    left: calc(100% - 23px);
    top: calc(5px);
  }
}

@media (min-width: 480px) {
  .resultsButtonFlex {
    flex-direction: row;
    justify-content: space-between;
    justify-content: flex-end;
    margin-top: 8px;
  }
  .resultsTopFlex {
    flex-direction: row;
    justify-content: space-between;
    justify-content: flex-end;
    margin-top: 8px;
  }
}
