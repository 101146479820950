// You can add anything that you want printed in the report by adding className="example-print" or "example-both"
// Currently not working, need to edit

.example-print {
  display: none;
}

@media print {
  .example-print {
    display: block !important;
  }
  .example-screen {
    display: none;
  }
}
