// Variables

.panelNeumorphism {
  border-top: #2a2a2a 1px solid;
  background-color: #f4f3ef !important;
  padding: 8px 0px 10px 0px;
  margin: 8px 18px 15px 18px;
}

@media (min-width: 600px) {
  .panelNeumorphism {
    border-top: none;
    box-shadow: 6px 6px 12px #a8a8a5, -6px -6px 12px #ffffff;
    background-color: #f4f3ef !important;
    margin: none;
    padding: 15px;
  }
}

@media (min-width: 900px) {
  .panelNeumorphism {
    padding: 30px;
  }
}

.resultsNeumorphism {
  box-shadow: 5px 5px 10px #a8a8a5, -5px -5px 10px #ffffff;
}

%lightBackgroundGradient {
  background: linear-gradient(
    135deg,
    rgba(251, 250, 250, 0.6) 0%,
    rgba(240, 240, 240, 0.6) 52%,
    rgba(237, 237, 237, 0.6) 96%,
    rgba(244, 244, 244, 0.6) 100%
  );
  backdrop-filter: blur(10px);
}

%lightShadow {
  -moz-box-shadow: 0 0 2px #a8a8a8;
  -webkit-box-shadow: 0 0 2px #a8a8a8;
  box-shadow: 0 0 2px #a8a8a8;
}

%darkShadow {
  -moz-box-shadow: 0 0 4px #a8a8a8;
  -webkit-box-shadow: 0 4px #a8a8a8;
  box-shadow: 0 0 4px #a8a8a8;
}

%lightInsetShadow {
  -moz-box-shadow: inset 0 0 2px #a8a8a8;
  -webkit-box-shadow: inset 0 0 2px #a8a8a8;
  box-shadow: inset 0 0 2px #a8a8a8;
}

%darkInsetShadow {
  -moz-box-shadow: inset 0 0 4px #a8a8a8;
  -webkit-box-shadow: inset 0 0 4px #a8a8a8;
  box-shadow: inset 0 0 4px #a8a8a8;
}
