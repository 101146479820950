@import "myThemes/_panel";
@import "myThemes/animate";
@import "_bootstrapOverrides";
@import "myThemes/_other";
@import "myThemes/results";
@import "myThemes/fonts";
@import "myThemes/print";
@import "myThemes/variables";
@import "myThemes/input";
@import "myThemes/table";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
