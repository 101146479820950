td,
th {
  text-align: center !important;
  vertical-align: middle !important;
  font-size: 0.9rem !important;
}

.salaryTableInput {
  min-width: 90px;
}

.tableScroll {
  max-width: 90vw;
  overflow: scroll;
}

.table {
  max-width: 100%;
}
