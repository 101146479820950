.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

// Buttons Grouped Together
.buttonFlex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.toggleFlex {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  padding-left: 0.8em;
  padding-right: 0.8em;
}

// Footer height adjustment
.content {
  min-height: calc(100vh - 80px);
}

.aboutToggle:hover {
  cursor: pointer;
  color: $primary;
}

@media (min-width: 900px) {
  .content {
    min-height: calc(100vh - 100px);
  }
}

#uncontrolled-tab-example-tab-info {
  margin: 5px;
}

#uncontrolled-tab-example-tab-advanced {
  margin: 5px;
}
